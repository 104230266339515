import React from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import styles from "../styles.module.css";
class TermoAceite extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#1A1E63",
          height: "100vh",
          paddingTop: "35px",
        }}
      >
        <Container fluid>
          <Row>
            <Col lg="3" md="3" />
            <Col lg="6" md="6">
              <div
                className={`card  ${styles.shadow}`}
                style={{ padding: "25px", marginBottom: "30px" }}
              >
                <div className="card-body">
                  <h3
                    className="card-title"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    Termos & condições
                  </h3>
                  <hr />
                  <p style={{fontFamily: "Raleway", textAlign: "justify"}}>
                    <br />
                    <strong>
                      Ao clicar para iniciar a prova, você estará
                      automaticamente declarando que leu, concorda e aceita
                      integralmente os termos abaixo. Responda ao email com o
                      ‘De acordo’ para que seja liberado o login para a sua
                      prova online. O ICI solicita o prazo de até 7 dias para a
                      liberação do link, portanto, programe-se.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Apenas envie o seu ‘De acordo’ quando estiver pronto para
                      fazer a prova, pois o link pode ser desativado dependendo
                      da demora no acesso.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      TERMO E CONDIÇÕES PARA A REALIZAÇÃO DA PROVA ONLINE E O
                      PROCESSO DE DIPLOMAÇÃO NO CURSO DE COACHING INTEGRADO PELO
                      ICI INTEGRATED COACHING INSTITUTE®.
                    </strong>
                    <br />
                    <br />
                    <ul>
                      <li>
                        <strong>
                          A prova online é parte integrante de 3 (três) etapas
                          para a diplomação do aluno, havendo necessidade do
                          aluno concluir com êxito as 3 (três) etapas, dentro
                          dos critérios estabelecidos pelo Departamento
                          Educacional do ICI para tanto.
                        </strong>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <strong>Etapa 1 = Curso completo;</strong>
                    <br />
                    <strong>Etapa 2 = Aprovação prova online;</strong>
                    <br />
                    <strong>
                      Etapa 3 = Aprovação na Gravação/Transcrição Literal e
                      Handouts.
                    </strong>
                    <br />
                    <strong>
                      Apenas a realização de uma ou duas etapas (de três no
                      total) não garante o diploma de conclusão de curso.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O prazo total para a diplomação é 6 (seis) meses pós
                      término do curso. Ou seja, após finalizar a ETAPA 1, o
                      aluno terá 6 meses para ter êxito na ETAPA 2 + ETAPA 3.
                    </strong>{" "}
                    Caso o aluno perca alguns dos prazos (já informados em outro
                    documento) e deseje ter seu diploma, poderá entrar em
                    contato com o email{" "}
                    <a href="mailto:educa@coachingintegrado.com.br">
                      educa@coachingintegrado.com.br 
                    </a>
                    <strong>
                       solicitando informações para a realização presencial de
                      uma RECICLAGEM OU REVALIDAÇÃO do aprendizado.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      É de total responsabilidade do aluno fazer a gestão dos
                      prazos de seu processo de certificação afim de
                      programar-se para cumprir as solicitações/ envios dentro
                      dos prazos estipulados atentando-se, inclusive aos prazos
                      internos requeridos para análise e considerações técnicas
                      do ICI, informados claramente em diversos documentos, como
                      resposta automática aos nossos envios e também
                      demonstrados neste documento.
                    </strong>
                    <br />
                    <br />
                    <strong>ETAPA 1: CURSO</strong>
                    <br />
                    <strong>
                      Para esta etapa ser considerada completa o aluno deverá
                      comparecer integralmente aos 8 dias do curso, sem exceção
                      e ter todas as atividades e provas em sala
                      entregues/realizadas.
                    </strong>
                    <br />
                    <br />
                    <strong>ETAPA 2: PROVA ONLINE</strong>
                    <br />
                    <strong>
                      A prova é composta de 50 (cinquenta) questões de múltipla
                      escolha, e o tempo total para a realização é de 60
                      (sessenta) minutos corridos, contados a partir do momento
                      que o aluno acessa o seu ambiente exclusivo de prova (link
                      de acesso + login + senha, enviados ao e-mail que o aluno
                      indicou previamente ao administrativo do ICI). O período
                      de acesso expira/ o conteúdo da prova fica indisponível
                      automaticamente findos os 60 (sessenta) minutos,
                      independente do aluno ter concluído a prova ou não. É de
                      total responsabilidade do aluno fazer a gestão de seu
                      tempo para que envie as respostas e caso necessário faça a
                      revisão de suas escolhas dentro desse tempo.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Como objetivo de evitar bloqueio no sistema de spam e
                      extravio dos dados de acesso à avaliação, sugerimos o
                      cadastramento prévio dos emails{" "}
                      <a href="mailto:invite@onlineexambuilder.com">
                        invite@onlineexambuilder.com
                      </a>{" "}
                      (envio dos dados de acesso à avalição online) e{" "}
                      <a href="mailto:educa@coachingintegrado.com.br">
                        educa@coachingintegrado.com.br
                      </a>{" "}
                      (o único canal para comunicação entre Educacional e Aluno
                      durante todo o processo).
                    </strong>
                    <br />
                    <br />
                    <strong>
                      É de inteira responsabilidade do aluno eventuais custos
                      que venha a ter com materiais de estudos, impressões de
                      documentos, e o que mais necessitar de apoio pessoal para
                      a realização da prova.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O aluno declara que o email fornecido por ele para envio
                      dos dados de acesso à sua prova online será o mesmo email
                      que o Educacional do ICI seguirá os contatos até ter
                      completado seu processo de diplomação. Caso haja qualquer
                      alteração, é de responsabilidade total do aluno atualizar
                      a informação através do email{" "}
                      <a href="mailto:educa@coachingintegrado.com.br">
                        educa@coachingintegrado.com.br
                      </a>
                      a fim de garantir o andamento de seu processo.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Nenhuma prova é igual à outra: do banco de centenas de
                      perguntas, a inteligência do sistema escolhe
                      randomicamente as 50 (cinquenta) questões que farão parte
                      para aquela prova gerada.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O conteúdo da prova e suas questões foram integralmente
                      extraídos dos 8 (oito) dias do curso de Formação
                      Internacional em Coaching Integrado, ou seja, todos os
                      temas abordados e explicitados durante o curso presencial,
                      fazem parte desta prova.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      A prova será considerada entregue/enviada apenas quando,
                      ao final das perguntas, o aluno clicar no botão “enviar
                      minhas respostas” e em seguida visualizar a tela de
                      conclusão, já com o resultado. É de inteira
                      responsabilidade do aluno responder às questões e
                      confirmar o envio de suas respostas, caso contrário a
                      prova não será computada no sistema.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Caso o aluno não tenha concluído sua prova (não tenha
                      enviado as respostas dentro desse tempo), e deseje
                      concluir o processo, será necessário fazer uma nova
                      solicitação de acesso, enviando mensagem, exclusivamente,
                      ao{" "}
                      <a href="mailto:educa@coachingintegrado.com.br">
                        educa@coachingintegrado.com.br
                      </a>
                    </strong>
                    <br />
                    <br />
                    <strong>
                      É de inteira responsabilidade do aluno garantir as
                      condições adequadas para a realização da prova: conexão
                      contínua com a internet, ambiente sem interferências
                      externas, estar preparado para responder as 50 questões,
                      disponibilizar 60 (sessenta) minutos em ambiente sem
                      interferências externas, uso preferencialmente de
                      computador, também podendo ser realizada através de alguns
                      tipos de smartfones.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Caso haja perda de conexão de internet do aluno, também se
                      fará necessária a solicitação de novos dados de acesso,
                      uma vez que o login só pode ser realizado uma única vez
                      dentro desse período de 60 (sessenta) minutos.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Ao término da avaliação o aluno receberá sua nota e seu
                      desempenho na tela de fechamento, bem como receberá via
                      email no mesmo endereço indicado para recebimento dos
                      dados de acesso da avaliação, uma página constando o
                      resultado final da prova teórica.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      A APROVAÇÃO NA PROVA ONLINE do aluno é regulamentada com o
                      acerto igual ou superior à 70% (setenta por cento) do
                      total das 50 (cinquenta) perguntas. Quando aprovado, o
                      aluno deverá AGUARDAR instruções para a próxima etapa que
                      serão enviadas ao seu email (em até 5 dias úteis).
                    </strong>
                    <br />
                    <br />
                    <strong>
                      A REPROVAÇÃO NA PROVA ONLINE do aluno é regulamentada com
                      acerto inferior à 70% (setenta por cento) do total das 50
                      (cinquenta) perguntas da prova. Caso seja reprovado,
                      sugerimos o aluno estudar um pouco mais o material e,
                      apenas quando estiver preparado para a realização de nova
                      avaliação, enviar um email para
                      <a href="mailto:educa@coachingintegrado.com.br">
                        educa@coachingintegrado.com.br
                      </a>
                      , solicitando novos dados de acesso.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O aluno poderá solicitar novas avaliações quantas vezes
                      forem necessárias para que atinja a pontuação mínima para
                      aprovação, desde que dentro do prazo pré-estabelecido.
                      Lembrando que o prazo total, desde o término do curso até
                      conclusão para certificação é 6 (seis) meses. É de total
                      responsabilidade do aluno atentar-se aos prazos do
                      processo.
                    </strong>
                    Caso não seja aprovado (nota abaixo de 70 pontos) em suas
                    tentativas durante esse período ou perca o prazo para
                    realização de sua prova online, e caso deseje o diploma, o
                    aluno deverá entrar em contato por email com o{" "}
                    <a href="mailto:educa@coachingintegrado.com.br">
                      educa@coachingintegrado.com.br
                    </a>{" "}
                    solicitando informações para a realização presencial de uma
                    RECICLAGEM OU REVALIDAÇÃO do aprendizado.
                    <br />
                    <br />
                    Não será possibilitado ao aluno, sob nenhuma hipótese, ter
                    acesso ao sistema para fazer a revisão de acertos e/ou erros
                    realizados na prova online. Esse acesso é restrito a
                    inteligência do programa, que pertence ao ICI.
                    <br />
                    <br />
                    <strong>
                      É proibido o aluno ceder ou compartilhar seus dados de
                      acesso, que são de uso pessoal e intransferível. Caso isso
                      ocorra, o aluno que disponibilizou a senha a terceiros se
                      responsabilizará por todo e qualquer encargo decorrentes
                      da má utilização desses dados, podendo o ICI tomar todas
                      as medidas necessárias para impedir que os envolvidos
                      nessa ação utilizem o acesso, além das devidas
                      providencias judiciais cabíveis. Caso terceiros venham a
                      responder a prova do aluno, o ICI poderá a qualquer
                      momento cancelar o resultado, bem como anular o diploma do
                      aluno.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O ALUNO declara estar ciente que é proibido fotografar,
                      gravar, copiar, veicular imagens, textos, e qualquer tipo
                      de conteúdo advindo do ambiente de acesso exclusivo a ele
                      destinado exclusivamente para a realização de sua prova e
                      teste de seus conhecimentos sobre os temas abordados. Tais
                      condutas ferem os princípios éticos, de confidencialidade
                      e de uso do material e estão sujeitos às providências
                      legais cabíveis.
                    </strong>
                    <br />
                    <br />
                    <strong>ETAPA 3: ENVIO DE DOCUMENTAÇÃO:</strong>
                    <br />
                    <strong>
                      Após a aprovação do ALUNO na prova online, o mesmo deverá
                      aguardar um email do ICI, validando o resultado da prova e
                      dando as orientações relevantes para a próxima etapa 3,
                      referente a gravação e documentos adicionais.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O pacote com a gravação e documentos só será aceito se
                      estiver completamente alinhado com os parâmetros
                      informados de forma clara no email de instruções para a
                      ETAPA 3. Caso algum documento esteja fora dos parâmetros
                      ou faltante, inaudível ou ilegível, todo o pacote será
                      automaticamente desconsiderado e o aluno informado por
                      email, para que possa preparar um novo envio, dentro dos
                      parâmetros.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O Coach deverá ter a anuência do Coachee para que a sessão
                      seja gravada. Essa aceitação pode ser apresentada por
                      escrito (no próprio contrato estabelecido entre Coach e
                      Coachee) ou verbalmente antes do inicio do atendimento
                      gravado.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O Coach tem o conhecimento que a sessão gravada tem fins
                      puramente educacionais e que o processo padrão de Coaching
                      possui 12 reuniões com o objetivo de desenvolver a
                      competência mapeada inicialmente na fase de coleta de
                      dados.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O atendimento deve ser feito seguindo a técnica
                      metodológica C.A.M.P.O. completa, na forma de uma conversa
                      espontânea, real e natural. O ICI desconsiderará
                      inteiramente, conversas ensaiadas, fazendo a anulação
                      irrestrita e reprovação instantânea do aluno.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      A transcrição, literal, da sessão deve ser apresentada no
                      formato do documento recebido em anexo a solicitação
                      oficial, logo após a validação da aprovação na prova
                      online.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      É recomendado que o aluno treine muito antes de enviar uma
                      gravação. A gravação da sessão que deve ser enviada é a
                      escolhida e a que o Coach reconhece que obteve a sua
                      melhor performance.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Em anexo ao pedido oficial que o aluno recebe por email,
                      será enviado também um documento de apoio para que o Coach
                      use como autoanálise, antes de enviar o seu material
                      final. Esse documento com a autoanálise poderá ser enviado
                      junto com o seu pacote educacional, mas não tem entrega
                      obrigatória. Vale salientar que este documento é apenas
                      para dar ao Coach a oportunidade de se auto observar,
                      porém, a sua autoanálise não prevalece sob nenhuma
                      hipótese, frente aos Mentores Técnicos ICI.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Quando o aluno envia o pacote de documentos conforme as
                      instruções, recebe automaticamente uma mensagem automática
                      de “Seu email foi recebido”, confirmando que recebemos a
                      mensagem. Em caso de documentação fora de padrão,
                      entraremos em contato com o aluno para informá-lo. Caso
                      contrário, basta o aluno AGUARDAR um período máximo de 60
                      dias para a conclusão da análise técnica educacional. O
                      resultado será enviado para o mesmo email utilizado desde
                      o início da comunicação entre aluno / Educacional.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Em caso de APROVAÇÃO NA ETAPA 3, o Coach receberá email
                      informativo e solicitando alguns dados para que o diploma
                      seja enviado, no prazo máximo de 20 dias úteis.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Em caso de REPROVAÇÃO NA ETAPA 3, o Coach receberá email
                      informativo, com instruções (prazos e especificações)
                      sobre como proceder para ter outra oportunidade de
                      submeter um novo pacote completo.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      A primeira análise de material não haverá nenhum custo
                      adicional para o aluno. À partir da segunda análise, o
                      aluno deverá fazer um pagamento no valor de R$ 300,00
                      (trezentos reais) pela nova análise. A cada novo envio de
                      pacote completo, após a primeira reprovação, haverá o
                      custo de R$ 300,00 por envio/ análise, referentes a
                      diplomação do mesmo curso.
                    </strong>{" "}
                    Neste caso, além das especificações do pacote da ETAPA 3, o
                    aluno deverá enviar também o comprovante de pagamento da
                    taxa, cujos dados serão fornecidos através do email de novas
                    instruções. Apenas após envio de comprovante e baixa do
                    financeiro, será realizada a nova análise. De novo, fique
                    atento aos prazos.
                    <br />
                    <br />
                    <strong>
                      A análise dos pacotes, é realizada por Coaches
                      credenciados ICI e ICF e completamente aptos na mentoria.
                      Os resultados e critérios de aprovação na prova online e
                      no processo de diplomação são feitos criteriosamente pelo
                      EDUCACIONAL ICI e não poderão ser contestados por
                      quaisquer motivos.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      ​O presente Termo tem por objetivo a prestação de serviços
                      educacionais, pelo ICI ao ALUNO, em ambiente virtual de
                      checagem de aprendizagem, tanto em formato de prova online
                      com respostas de múltipla escolha conforme cláusulas
                      acima, quanto em formato de envio de documentação completa
                      para análise da atuação prática do coach, em formato
                      específico para diplomação.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O aluno se obriga a observar as disposições do presente
                      Termo e todas as demais normas e procedimentos previstos
                      na checagem de aprendizado do Curso de Formação em
                      Coaching, e cujas cláusulas são parte integrante deste
                      instrumento.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      O aluno é responsável civil e criminalmente pela
                      veracidade dos dados, declarações, informações que
                      fornecer ao EDUCACIONAL ICI e ao ADMINISTRATIVO ICI e
                      pelas consequências que deles advierem.
                    </strong>{" "}
                    <br />
                    <br />
                    <strong>
                      ​Parágrafo Único: ​O ICI poderá, a qualquer momento,
                      alterar/modificar o presente Termo, por meio de aditivo.
                      Se qualquer das cláusulas deste Termo for considerada nula
                      ou anulável, tal cláusula será desconsiderada,
                      permanecendo as demais válidas, em pleno vigor e efeito.
                    </strong>{" "}
                    <br />
                    <br />
                    <strong>
                      Fica eleito o foro do domicílio do ICI como competente
                      para dirimir questões oriundas do presente Termo.
                    </strong>
                    <br />
                    <br />
                    <strong>São Paulo, janeiro 2018.</strong>
                    <br />
                    <br />
                    Atualizado em maio 2018
                    <br />
                    Atualizado em julho 2018
                    <br />
                    Atualizado em outubro 2018
                    <br />
                    Atualizado em novembro 2018
                    <br />
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="3" md="3" />
          </Row>
        </Container>
      </div>
    );
  }
}

export default TermoAceite;
