import React from "react";
import api from "../../../services/api";
import { fazHash, desfazHash } from "../../../services/hash";
import { Link, useHistory, withRouter } from "react-router-dom";

class ResultadoFinal extends React.Component {
  state = {
    pontuacao: "",
    status: "",
    tempo: "",
  };

  backHome() {
    this.props.history.push("/");
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const data = new FormData();
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    data.append("value", this.props.match.params.value);
    api
      .post("provas/pount-status-time", data)
      .then((res) => {
        this.setState({
          pontuacao: res.data.pontuacao,
          status: res.data.status,
          tempo: res.data.tempo,
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  render() {
    return (
      <div style={{ backgroundColor: "#1A1E63", height: "100vh" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              style={{
                backgroundColor: "#fff",
                padding: "65px",
                height: "100vh",
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <button
                    style={{
                      backgroundColor: "#EFA884",
                      color: "#82604E",
                      border: "2px #E5A17E solid",
                      borderRadius: "50px",
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "10px 25px 10px 25px",
                    }}
                    type="button"
                    onClick={() => this.backHome()}
                  >
                    Voltar para home
                  </button>
                </div>
                <div className="col-md-12 d-flex justify-content-center" style={{marginTop: "105px"}}>
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12 d-flex justify-content-center">
                      <h1
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "300",
                          color: "#5A5077",
                          fontSize: "46px",
                          marginBottom: "20px",
                        }}
                      >
                        Sua pontuação é{" "}
                        <span style={{ fontWeight: "700" }}>
                          {this.state.pontuacao}%
                        </span>
                      </h1>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                      <h3
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "300",
                          color: "#444444",
                          fontSize: "26px",
                        }}
                      >
                        Você terminou a prova. Você fez{" "}
                        <span style={{ fontWeight: "600" }}>
                          {this.state.pontuacao}.00
                        </span>{" "}
                        pontos de 100
                      </h3>
                    </div>
                    <div
                      className="col-md-12 d-flex justify-content-center"
                      style={{ marginTop: "15px" }}
                    >
                      {this.state.status === "1" && (
                        <span
                          style={{
                            color: "#fff",
                            backgroundColor: "#4AC781",
                            border: "2px #2FA462 solid",
                            borderRadius: "50px",
                            fontFamily: "Raleway",
                            fontWeight: "600",
                            fontSize: "16px",
                            padding: "5px 25px 5px 25px",
                          }}
                        >
                          Aprovado
                        </span>
                      )}
                      {this.state.status === "0" && (
                        <span
                          style={{
                            color: "#fff",
                            backgroundColor: "#DC1212",
                            border: "2px #CA1010 solid",
                            borderRadius: "50px",
                            fontFamily: "Raleway",
                            fontWeight: "600",
                            fontSize: "16px",
                            padding: "5px 25px 5px 25px",
                          }}
                        >
                          Reprovado
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResultadoFinal);
