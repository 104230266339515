import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import styles from "./styles.module.css";

class ButtonDocSegundoEnvioComponent extends React.Component {
  state = {
    order: ""
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if(this.props.provaid) {
      this.setState({
        order: "first"
      })
    } else if(this.props.provarep) {
      this.setState({
        order: "second"
      })
    }
  }

  visualizarForm() {
    this.props.history.push(`/prova/${this.props.id}/${this.state.order}/segundo-envio/envio-documentacao`);
  }

  render() {
    return (
      <>
        <button className={`${styles.btntest}`}
          onClick={() => this.visualizarForm()}
        >
          Enviar documentação
        </button>
      </>
    );
  }
}

export default withRouter(ButtonDocSegundoEnvioComponent);
