import React from "react";
import api from "../../services/api";
import { fazHash, desfazHash } from "../../services/hash";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import ProvaDashboard from "./ProvaDashboard";
import Header from "views/Header";
import { FiInfo } from "react-icons/fi";

class Dashboard extends React.Component {
  state = {
    provas: [],
    provasRep: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    api
      .get(
        `provas/list-prova-by-user/${desfazHash(localStorage.getItem("id"))}`
      )
      .then((res) => {
        this.setState({
          provas: res.data.prova,
          provasRep: res.data.prova_rep,
        });
      })
      .catch((error) => console.warn(error));
  }

  render() {
    const { provas, provasRep } = this.state;
    return (
      <>
        <Header />
        <Container>
          <Row style={{ marginTop: "60px" }}>
            <Col lg="12" md="12">
              <h2
                style={{
                  fontFamily: "Raleway",
                  fontWeight: "600",
                  color: "#171725",
                }}
              >
                Olá, {desfazHash(localStorage.getItem("user"))}.
              </h2>
              <div
                className="alert alert-info"
                role="alert"
                style={{
                  backgroundColor: "#E8F2FF",
                  border: "0px",
                  fontFamily: "Raleway",
                  color: "#466285",
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "inline-block",
                  width: "100%"
                }}
              >
                <FiInfo
                  style={{
                    float: "left",
                    marginRight: "10px",
                    marginTop: "1.5px",
                    fontWeight: "800",
                    fontSize: "17px",
                    color: "#0054bf"
                  }}
                />{" "}
                <span>
                  Aqui você encontrará informações do seu desempenho nas provas,
                  status de envio das documentações.
                </span>
              </div>
            </Col>
            <Col lg="12" md="12" style={{ marginTop: "30px" }}>
              {provas.map((prova, index) => (
                <>
                  <ProvaDashboard
                    title={prova.titulo}
                    status={prova.status}
                    pontuacao={prova.pontuacao}
                    prazo={prova.prazoProva}
                    id={prova.id}
                    provaid={prova.prova_id}
                  />
                </>
              ))}
              {provasRep.map((prova, index) => (
                <>
                  <ProvaDashboard
                    title={prova.titulo}
                    status={prova.status}
                    pontuacao={prova.pontuacao}
                    prazo={prova.prazoProva}
                    id={prova.id}
                    provareprovadoid={prova.prova_reprovado_id}
                  />
                </>
              ))}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Dashboard);
