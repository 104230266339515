import React from "react";

class TitleComponent extends React.Component {
  render() {
    return (
      <>
        <h4
          style={{ fontFamily: "Raleway", fontWeight: "600", color: "#191719" }}
        >
          {this.props.title}
          {this.props.status === "1" && (
            <span
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: "800",
                backgroundColor: "#CCF5E0",
                color: "#0AA957",
                padding: "9px",
                borderRadius: "50px",
                textAlign: "center",
                marginLeft: "15px",
                marginTop: "-7px"
              }}
            >
              Aprovado
            </span>
          )}
          {this.props.status === "0" && (
            <span
              style={{
                fontFamily: "Raleway",
                fontSize: "12px",
                fontWeight: "800",
                backgroundColor: "#f5cccc",
                color: "#cf0606",
                padding: "9px",
                borderRadius: "50px",
                textAlign: "center",
                marginLeft: "15px",
                marginTop: "-7px"
              }}
            >
              Reprovado
            </span>
          )}
        </h4>
      </>
    );
  }
}

export default TitleComponent;
