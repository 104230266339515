import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import ReactDOM from "react-dom";
import App from "./App";
import { Alert } from "react-bootstrap";
import Modal from "react-modal";
import './styles.css'

Modal.setAppElement("#root");

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Alert variant="info">
                <p>Aguarde enquanto buscamos as informações.</p>
              </Alert>
            </div>
          </div>
        </div>
      </>
    )
  );
};

ReactDOM.render(
  <div>
    <App />
    <LoadingIndicator />
  </div>,
  document.getElementById("root")
);
