import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import { logout } from "../../services/auth";
import "./styles.css";

class Header extends React.Component {
  logout() {
    logout();
    this.props.history.push("/login");
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#1A1E63", height: "180px" }}>
          <Navbar
            expand="md"
            fixed="top"
            variant="dark"
            style={{ backgroundColor: "#1A1E63" }}
          >
            <Container>
              <Navbar.Brand href="/">
                <img
                  src="https://i.imgur.com/NUDInv5.png"
                  style={{ marginTop: "-15px" }}
                ></img>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Nav>
                  <Nav.Link href="/">Meu dashboard</Nav.Link>
                  <Nav.Link eventKey={2} onClick={() => this.logout()}>
                    Sair
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
