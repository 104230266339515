import axios from "axios";
import { getToken } from "./auth";
import axiosRetry from 'axios-retry';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosRetry(api, { retries: 3, retryDelay: (retryCount) => {
  return retryCount * 1000;
}});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  res => {
    return res;
  },
  error => {
    // const token = getToken();
    if (error.response && error.response.status === 401) {
      return axios.request(error);
    }

    return Promise.reject(error);
  }
);

export default api;
