import React from "react";

class InfoUpload extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#FEF5CA",
          borderRadius: "8px",
          padding: "20px 35px 20px 30px",
          fontSize: "14px",
          fontFamily: "Raleway",
          fontWeight: "500",
          color: "#606365",
          textAlign: "justify",
          marginBottom: "40px",
        }}
      >
        Envie todos os{" "}
        <span style={{ fontWeight: "700" }}>
          documentos requisitados conforme as especificações recebidas por
          e-mail
        </span>
        . Ao enviar os documentos, siga todos esses formatos:
        <br />
        <br />• O áudio deve obedecer o <i>limite mínimo</i> de{" "}
        <span style={{ fontWeight: "700", color: "#E94F20" }}>
          44 minutos de duração e máximo de 55 minutos
        </span>
        <br />• Os formatos de <i>áudio</i> aceitos são{" "}
        <span style={{ fontWeight: "700", color: "#E94F20" }}>
          MP3, AVI, WAV, OGG, WMA
        </span>
        <br />• Os formatos aceitos da <i>transcrição e auto análise</i> são:{" "}
        <span style={{ fontWeight: "700", color: "#E94F20" }}>PDF ou DOC</span>
      </div>
    );
  }
}

export default InfoUpload;
