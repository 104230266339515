import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../services/api";
import { desfazHash, fazHash } from "../../../services/hash";
import styles from "../styles.module.css";
import { Folder } from "react-feather";
import Upload from "../upload/Upload";
import FileList from "../upload/FileList";
import { uniqueId } from "lodash";
import filesize from "filesize";
import FileUploadProgress from "react-fileupload-progress";
import "../styles.css";
import { CircleSpinner } from "react-spinners-kit";
import S3 from "react-aws-s3";
class AudioUpload extends React.Component {
  state = {
    uploadedFiles: [],
    textError: null,
    countdown: 0,
    timeUntilDismissed: 5,
    running: true,
    visible: false,
    time: 500,
    number: 1,
  };

  constructor(props) {
    super(props);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  handleStart(id) {
    this.timer = setInterval(() => {
      const newTime = this.state.time - 1;
      this.setState({ time: newTime >= 0 ? newTime : 0 });
      if (this.state.progress != 100) {
        this.setState({
          number: this.state.number + 1,
        });
        this.updateFile(id, {
          progress: this.state.number + 1,
        });
      }
    }, 1000);
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  handleCountdown(seconds) {
    this.setState({
      time: seconds,
      running: true,
    });
  }

  componentDidMount() {}

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.validateFile);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  processUpload = (uploadedFile) => {
    this.handleStart(uploadedFile.id);

    const config = {
      bucketName: "ici-provas-online",
      region: "sa-east-1",
      accessKeyId: "AKIAWSZ2ODF7KXLHGO3Q",
      secretAccessKey: "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",
      s3Url: "https://ici-provas-online.s3-sa-east-1.amazonaws.com",
      onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
    };

    const ReactS3Client = new S3(config);

    const newFileName = this.makeid(30);

    ReactS3Client.uploadFile(uploadedFile.file, newFileName)
      .then((res) => {
        this.updateFile(uploadedFile.id, {
          progress: 100,
        });

        const data = new FormData();

        data.append("value", this.props.match.params.value);
        data.append("user_id", desfazHash(localStorage.getItem("id")));
        data.append("prova_id", this.props.match.params.id);
        data.append("type", "audio");
        data.append("url", res.location);
        api
          .post("documentos/store-audio-url", data)
          .then((res) => this.props.parentCallback(true))
          .catch((error) => console.warn(error));
      })
      .catch((err) => console.error(err));
  };

  handleDelete = async (id) => {
    await api.delete(`posts/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach((file) =>
      URL.revokeObjectURL(file.preview)
    );
  }

  validateFile = (uploadedFile) => {
    let name = uploadedFile.name;
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext === "mp3" ||
      ext === "avi" ||
      ext === "mp4" ||
      ext === "ogg" ||
      ext === "wav" ||
      ext === "wma"
    ) {
      return new Promise((resolve) => {
        var objectURL = URL.createObjectURL(uploadedFile.file);
        var mySound = new Audio([objectURL]);
        mySound.addEventListener(
          "canplaythrough",
          () => {
            this.setState({
              textError: null,
            });
            URL.revokeObjectURL(objectURL);
            resolve({
              uploadedFile,
              duration: mySound.duration,
            });
            this.setState({
              durationAudio: mySound.duration,
            });

            if (
              this.state.durationAudio >= 2640 &&
              this.state.durationAudio <= 3300
            ) {
              this.processUpload(uploadedFile);
              this.setState({
                textError: null,
              });
            } else {
              var array = [...this.state.uploadedFiles];
              var index = array.indexOf(uploadedFile);
              if (index !== -1) {
                array.splice(index, 1);
                this.setState({ uploadedFiles: array });
              }

              this.setState({
                textError:
                  "A duração do áudio deve ser entre 44 à 55 minutos. Por favor, tente novamente.",
              });
            }
          },
          false
        );
      });
    } else {
      var array = [...this.state.uploadedFiles];
      var index = array.indexOf(uploadedFile);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ uploadedFiles: array });
      }

      this.setState({
        textError:
          "O áudio deve ser no formato MP3, MP4, OGG, WAV, WMA, AVI. Por favor, tente novamente.",
      });
    }
  };

  render() {
    const { uploadedFiles, textError } = this.state;

    return (
      <div>
        <label style={{ fontFamily: "Raleway" }}>
          <strong>Áudio</strong>
        </label>
        {uploadedFiles.length === 0 && <Upload onUpload={this.handleUpload} />}
        {textError != null && <div className="text-danger">{textError}</div>}
        {!!uploadedFiles.length && (
          <FileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
      </div>
    );
  }
}

export default withRouter(AudioUpload);
