import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Prova from "./views/Prova";
import TermoAceite from "./views/Prova/TermoAceite";
import ProsseguirAceite from "views/Prova/ProsseguirAceite";
import ProvaQuestao from "views/Prova/ProvaQuestao";
import ProvaRealizada from "views/Prova/ProvaRealizada";
import ResultadoFinal from "views/Prova/ResultadoFinal";
import Documentacao from "views/Documentacao";
import DocumentacaoRep from "views/DocumentacaoRep";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" exact render={(props) => <Login {...props} />} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute
        path="/"
        exact
        component={(props) => <Dashboard {...props} />}
      />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/termos-de-aceite" exact render={(props) => <TermoAceite {...props} />} />
      <Route path="/prova/:id/:value/envio-documentacao" exact render={(props) => <Documentacao {...props} />} />
      <Route path="/prova/:id/:value/segundo-envio/envio-documentacao" exact render={(props) => <DocumentacaoRep {...props} />} />
      <Route path="/prova/:id/:code/:value/:email/:hash" exact render={(props) => <Prova {...props} />} />
      <Route path="/prova/:id/:code/:value/:email/:hash/aceite" exact render={(props) => <ProsseguirAceite {...props} />} />
      <Route path="/prova/:id/:value/questao" exact render={(props) => <ProvaQuestao {...props} />} />
      <Route path="/prova-realizada" exact render={(props) => <ProvaRealizada {...props} />} />
      <Route path="/prova/:value/resultado-final/:id" exact render={(props) => <ResultadoFinal {...props} />} />
      <Route path="/prova/:id/overview" exact render={(props) => <Documentacao {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

export default Routes;
