import React from "react";
import api from "../../../services/api";
import { fazHash, desfazHash } from "../../../services/hash";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import styles from "../styles.module.css";
class ProsseguirAceite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    if (target.checked === true) {
      this.setState({
        checked: true,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const parameter = this.props.match.params.value;
    const data = new FormData();
    data.append("value", parameter);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    api
      .post("provas/list-exam-by-student", data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  goToProvaQuestao() {
    this.props.history.push(
      `/prova/${this.props.match.params.id}/${this.props.match.params.value}/questao`
    );
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "#1A1E63",
          height: "100vh",
          paddingTop: "35px",
        }}
      >
        <Container fluid>
          <Row>
            <Col lg="3" md="3" />
            <Col lg="6" md="6">
              <div
                className={`card  ${styles.shadow}`}
                style={{ padding: "25px", marginBottom: "30px" }}
              >
                <div className="card-body">
                <div className="d-flex justify-content-end"><h6>{desfazHash(localStorage.getItem("user"))}</h6></div>
                  <h3
                    className="card-title"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    Entrar e começar
                  </h3>
                  <hr />
                  <form onSubmit={this.handleSubmit}>
                    <Col
                      lg="12"
                      md="12"
                      className="d-flex justify-content-center"
                      style={{ marginTop: "25px" }}
                    >
                      <div className="form-check">
                        <input
                          type="checkbox"
                          onChange={this.handleInputChange}
                          className="form-check-input"
                        />
                        <label className="form-check-label">
                          Eu concordo com os{" "}
                          <strong>
                            <Link to="/termos-de-aceite">termos</Link>
                          </strong>
                          *
                        </label>
                      </div>
                    </Col>
                    <Col
                      lg="12"
                      md="12"
                      style={{ marginTop: "7px" }}
                      className="d-flex justify-content-center"
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={this.state.checked === false}
                        onClick={() => this.goToProvaQuestao()}
                      >
                        Estou pronto para começar a prova!
                      </Button>
                    </Col>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg="3" md="3" />
          </Row>
        </Container>
      </div>
    );
  }
}

export default ProsseguirAceite;
