import React from "react";
import api from "../../../services/api";
import { fazHash, desfazHash } from "../../../services/hash";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import tw from "twin.macro";
import "./styles.css";
import { CircleSpinner } from "react-spinners-kit";

class ProvaQuestao extends React.Component {
  state = {
    questoes: [],
    currentPage: 0,
    postsPerPage: 1,
    currentPosts: [],
    alternativas: [],
    user_has_questions_id: "",
    alternativa_respondida_id: "",
    currentPageComparation: 0,
    countdown: 0,
    timeUntilDismissed: 5,
    running: true,
    time: localStorage.getItem("count"),
    visible: false,
    pageNumbers: [],
    loading: false,
  };

  constructor(props) {
    super(props);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  componentDidMount() {
    const data = new FormData();
    data.append("value", this.props.match.params.value);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    api
      .post("provas/verify-exam", data)
      .then((res) => {
        if (
          res.data.finish != null &&
          res.data.pontuacao != null &&
          res.data.status != null
        ) {
          this.props.history.push("/prova-realizada");
        } else {
          this.getProvaUser();
          this.handleStart();
          localStorage.setItem("start", true);
        }
      })
      .catch((error) => console.log(error));

    for (let i = 0; i <= Math.ceil(49 / this.state.postsPerPage); i++) {
      this.state.pageNumbers.push(i);
    }
  }

  getProvaUser() {
    const data = new FormData();
    data.append("value", this.props.match.params.value);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    api
      .post("provas/list-questions-by-code", data)
      .then((response) => {
        this.setState({
          questoes: response.data,
        });

        const data = response.data;
        const slice = data.slice(
          this.state.currentPage,
          this.state.currentPage + this.state.postsPerPage
        );
        const indexOfLastPost =
          this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost =
          this.state.indexOfLastPost - this.state.postsPerPage;

        this.setState({
          currentPosts: slice,
        });

        api
          .get(
            `/alternativas/alternative-questions/${desfazHash(
              localStorage.getItem("questao_id")
            )}`
          )
          .then((res) => {
            this.setState({
              alternativas: res.data,
            });

            this.getAlternativaRespondida();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }

  getAlternativaRespondida() {
    const data = new FormData();
    data.append("value", this.props.match.params.value);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    data.append("questao_id", desfazHash(localStorage.getItem("questao_id")));
    api
      .post("questoes/alternative-answer-by-question", data)
      .then((response) => {
        this.setState({
          user_has_questions_id: response.data["0"].id,
          alternativa_respondida_id: response.data["0"].alternativa_id,
        });
      })
      .catch((error) => {
        this.setState({
          user_has_questions_id: null,
          alternativa_respondida_id: null,
        });

        console.log(error);
      });
  }

  paginate = (pageNumber) => {
    console.log(pageNumber);
    this.setState(
      {
        currentPage: pageNumber,
        currentPageComparation: pageNumber,
      },
      () => {
        this.getProvaUser();
      }
    );

    if (localStorage.getItem("pageMax") <= pageNumber) {
      localStorage.setItem("pageMax", pageNumber);
    }
  };

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  handleStart() {
    this.timer = setInterval(() => {
      const newTime = this.state.time - 1;
      localStorage.setItem("count", newTime);
      this.setState({ time: newTime >= 0 ? newTime : 0 });

      if (newTime === 0) {
        this.finalizarProva();
      }
    }, 1000);
  }

  handleStop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ running: false });
    }
  }

  handleReset() {
    this.setState({ time: 0 });
  }

  handleCountdown(seconds) {
    this.setState({
      time: seconds,
      running: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.running !== prevState.running) {
      switch (this.state.running) {
        case true:
          this.handleStart();
          break;
      }
    }
  }

  onChangeCheckedAlternativas(id, questao) {
    this.setState({
      alternativa_respondida_id: id,
    });

    const data = new FormData();
    data.append("questao_id", questao);
    data.append("alternativa_id", id);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    data.append("value", this.props.match.params.value);
    api
      .post("alternativas/store-user-response", data)
      .then((res) => {
        this.getProvaUser();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  format(time) {
    const date = new Date(time * 1000);
    let hh = date.getUTCHours();
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    return "00" !== hh ? hh + ":" + mm + ":" + ss : mm + ":" + ss;
  }

  finalizarProva() {
    const data = new FormData();
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    data.append("value", this.props.match.params.value);
    api
      .post("provas/store-result", data)
      .then((res) => {
        localStorage.removeItem("pageMax");
        localStorage.setItem("count", 3600);
        localStorage.removeItem("start");
        this.props.history.push(
          `/prova/${this.props.match.params.value}/resultado-final/${this.props.match.params.id}`
        );
      })
      .catch((error) => console.warn(error));
  }

  render() {
    const {
      currentPosts,
      alternativas,
      alternativa_respondida_id,
      user_has_questions_id,
      time,
      pageNumbers,
      currentPageComparation,
      loading,
    } = this.state;
    return (
      <div style={{ backgroundColor: "#1A1E63", height: "100vh" }}>
        <Container>
          <Row>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "65px",
                height: "100vh",
              }}
            >
              {currentPosts.map((questao, index) => (
                <>
                  {localStorage.setItem("questao_id", fazHash(questao.id))}
                  <div className="row">
                    <div className="col-md-8">
                      <h6
                        style={{
                          color: "#D3D2DA",
                          fontFamily: "Open Sans",
                          fontWeight: "700",
                        }}
                      >
                        Questão {this.state.currentPage + 1}
                      </h6>
                    </div>
                    <div className="col-md-4">
                    <div className="d-flex justify-content-end"><h6>{desfazHash(localStorage.getItem("user"))}</h6></div>
                      <div
                        className="d-flex justify-content-end"
                        style={{
                          color: "#5A5077",
                          fontFamily: "Raleway",
                          fontSize: "18px",
                        }}
                      >
                        Tempo restante:{" "}
                        <span style={{ fontWeight: "600", marginLeft: "20px" }}>
                          00:{this.format(time)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-md-12">
                      <h2
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "300",
                          color: "#5A5077",
                          marginBottom: "35px",
                        }}
                      >
                        {questao.titulo}
                      </h2>
                    </div>
                  </div>
                  {alternativas.map((alternativa) => (
                    <>
                      <div>
                        <div
                          className={
                            "radio " +
                            (alternativa_respondida_id === alternativa.id
                              ? "input-checked-yes"
                              : "")
                          }
                          style={{ paddingTop: "15px" }}
                        >
                          <label>
                            <input
                              type="radio"
                              name="alternativa"
                              key={alternativa.id}
                              checked={
                                alternativa_respondida_id === alternativa.id
                              }
                              onChange={() =>
                                this.onChangeCheckedAlternativas(
                                  alternativa.id,
                                  questao.id
                                )
                              }
                            />
                            <span style={{ marginLeft: "8px" }}></span>
                            <label
                              style={{
                                fontFamily: "Raleway",
                                marginLeft: "15px",
                                marginTop: "-4px",
                                color: "#5A5077",
                                fontWeight: "500",
                                fontSize: "17px",
                              }}
                            >
                              {alternativa.texto}
                            </label>
                          </label>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ))}
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "35px" }}
              >
                {this.state.currentPage != 49 && (
                  <button
                    style={{
                      backgroundColor: "#FF7A7A",
                      color: "#fff",
                      border: "1px #D85959 solid",
                      borderRadius: "50px",
                      fontFamily: "Raleway",
                      fontSize: "15px",
                      fontWeight: "600",
                      padding: "10px 15px 10px 15px",
                    }}
                    type="button"
                    disabled={alternativa_respondida_id === null}
                    onClick={() => this.paginate(currentPageComparation + 1)}
                  >
                    Próxima questão
                  </button>
                )}
                {this.state.currentPage === 49 && (
                  <button
                    style={{
                      backgroundColor: "#FF7A7A",
                      color: "#fff",
                      border: "1px #D85959 solid",
                      borderRadius: "50px",
                      fontFamily: "Raleway",
                      fontSize: "15px",
                      fontWeight: "600",
                      padding: "10px 15px 10px 15px",
                    }}
                    type="button"
                    disabled={alternativa_respondida_id === null}
                    onClick={() => this.finalizarProva()}
                  >
                    {loading === true && (
                      <>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ paddingLeft: "8px" }}
                          >
                            <CircleSpinner
                              size={20}
                              color="#fff"
                              loading={loading}
                            ></CircleSpinner>
                          </div>
                          <div className="col">
                            <span
                              style={{
                                paddingTop: "4px",
                                verticalAlign: "middle",
                              }}
                            >
                              Salvando resultados...
                            </span>
                          </div>
                        </div>
                      </>
                    )}{" "}
                    {loading === false && <>Finalizar prova</>}
                  </button>
                )}
              </div>
              <div style={{ marginTop: "25px" }}>
                <div>
                  {" "}
                  <nav>
                    <ul
                      className="paginationb justify-content-center"
                      style={{
                        columnCount: "1",
                      }}
                    >
                      {pageNumbers.map((number) => (
                        <>
                          <li key={number} style={{ float: "left" }}>
                            {localStorage.getItem("pageMax") < number ? (
                              <>
                                <a
                                  onClick={
                                    localStorage.getItem("pageMax") < number
                                      ? ""
                                      : () => this.paginate(number)
                                  }
                                  className="page-link"
                                  style={{
                                    cursor: "not-allowed",
                                    opacity: "0.5",
                                    textDecoration: "none",
                                  }}
                                >
                                  {number <= 8 && <>0{number + 1}</>}
                                  {number >= 9 && <>{number + 1}</>}
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  onClick={() => this.paginate(number)}
                                  className="page-link"
                                >
                                  {currentPageComparation === number && (
                                    <>
                                      <span
                                        style={{
                                          color: "#ad002e",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {number <= 8 && <>0{number + 1}</>}
                                        {number >= 9 && <>{number + 1}</>}
                                      </span>
                                    </>
                                  )}
                                  {currentPageComparation !== number && (
                                    <>
                                      {number <= 8 && <>0{number + 1}</>}
                                      {number >= 9 && <>{number + 1}</>}
                                    </>
                                  )}
                                </a>
                              </>
                            )}
                          </li>
                        </>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ProvaQuestao;
