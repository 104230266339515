import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../services/api";
import { desfazHash, fazHash } from "../../../services/hash";
import styles from "../styles.module.css";
import { Folder } from "react-feather";
import Upload from "../upload/Upload";
import FileList from "../upload/FileList";
import { uniqueId } from "lodash";
import filesize from "filesize";
import FileUploadProgress from "react-fileupload-progress";
import "../styles.css";
import { CircleSpinner } from "react-spinners-kit";

class ComprovantePagamento extends React.Component {
  state = {
    uploadedFiles: [],
    textError: null,
  };

  componentDidMount() {}

  handleUpload = (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(this.validateFile);
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  processUpload = (uploadedFile) => {
    const data = new FormData();

    data.append("image", uploadedFile.file, uploadedFile.name);
    data.append("value", this.props.match.params.value);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("prova_id", this.props.match.params.id);
    data.append("type", "comprovantePagamento");
    api
      .post("documentos/store-files", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          this.updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url,
        });
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  handleDelete = async (id) => {
    await api.delete(`posts/${id}`);

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id),
    });
  };

  componentWillUnmount() {
    this.state.uploadedFiles.forEach((file) =>
      URL.revokeObjectURL(file.preview)
    );
  }

  validateFile = (uploadedFile) => {
    let name = uploadedFile.name;
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext === "pdf" || ext === "doc" || ext === "docx") {
      this.setState({
        textError: null,
      });

      this.processUpload(uploadedFile);
    } else {
      var array = [...this.state.uploadedFiles];
      var index = array.indexOf(uploadedFile);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({
          uploadedFiles: array,
        });
      }

      this.setState({
        textError:
          "O arquivo deve ser no formato PDF, DOC ou DOCX. Por favor, tente novamente.",
      });
    }
  };

  render() {
    const { uploadedFiles, textError } = this.state;

    return (
      <div>
        <label style={{ fontFamily: "Raleway" }}>
          <strong>Comprovante de pagamento</strong>
        </label>
        {uploadedFiles.length === 0 && <Upload onUpload={this.handleUpload} />}
        {textError != null && <div className="text-danger">{textError}</div>}
        {!!uploadedFiles.length && (
          <FileList files={uploadedFiles} onDelete={this.handleDelete} />
        )}
      </div>
    );
  }
}

export default withRouter(ComprovantePagamento);
