import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import styles from "./styles.module.css";

class ButtonComponent extends React.Component {
  visualizarProva() {
    this.props.history.push(`/prova/${this.props.id}`);
  }

  render() {
    return (
      <>
        <button className={`${styles.btntest}`}
          onClick={() => this.visualizarProva()}
        >
          Visualizar prova
        </button>
      </>
    );
  }
}

export default ButtonComponent;
