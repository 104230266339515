import React from "react";
import api from "../../services/api";
import { login } from "../../services/auth";
import { fazHash, desfazHash } from "../../services/hash";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import styles from "./styles.module.css";

class Prova extends React.Component {
  state = {
    prova: "",
  };
  componentDidMount() {
    this.getLogin(this.props.match.params.email, this.props.match.params.code);
  }

  getLogin(email, password) {
    try {
      api
        .post(
          "login",
          { email, password },
          {
            credentials: "include",
          }
        )
        .then((response) => {
          login(response.data.access_token);
          localStorage.setItem("user", fazHash(response.data.name));
          localStorage.setItem("email", fazHash(response.data.email));
          localStorage.setItem("id", fazHash(response.data.id));
          localStorage.setItem("type", fazHash(response.data.type));
          localStorage.setItem("type_log", response.data.type);
          
          // Pegar dados da prova
          if (this.props.match.params.value === "first") {
            api
              .get(`provas/${this.props.match.params.id}`)
              .then((res) => {
                this.setState({
                  prova: res.data,
                });

                if (res.data.especial === 0) {
                  if(!localStorage.getItem("start")) {
                    localStorage.setItem("count", 3600);
                  }  
                } else if (res.data.especial === 1) {
                  if(!localStorage.getItem("start")) {
                    localStorage.setItem("count", 7200);
                  }  
                }

                const data = new FormData();
                data.append("user_id", desfazHash(localStorage.getItem("id")));
                data.append("prova_id", res.data.id);
                data.append("value", this.props.match.params.value);
                api
                  .post("provas/store-start-time", data)
                  .then((res) => console.log(res.data))
                  .catch((error) => console.warn(error));
              })
              .catch((error) => console.log(error));
          } else if (this.props.match.params.value === "second") {
            api
              .get(`provas-reprovados/${this.props.match.params.id}`)
              .then((res) => {
                this.setState({
                  prova: res.data,
                });

                console.log("aqui: " + localStorage.getItem("start"))

                if (res.data.especial === 0) {
                  if(!localStorage.getItem("start")) {
                    localStorage.setItem("count", 3600);
                  }  
                } else if (res.data.especial === 1) {
                  if(!localStorage.getItem("start")) {
                    localStorage.setItem("count", 7200);
                  }  
                }

                const data = new FormData();
                data.append("user_id", desfazHash(localStorage.getItem("id")));
                data.append("prova_id", res.data.id);
                data.append("value", this.props.match.params.value);
                api
                  .post("provas-reprovados/store-start-time", data)
                  .then((res) => console.log(res.data))
                  .catch((error) => console.warn(error));
              })
              .catch((error) => console.log(error));
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  goToAceite() {
    this.props.history.push(
      `/prova/${this.props.match.params.id}/${this.props.match.params.code}/${this.props.match.params.value}/${this.props.match.params.email}/${this.props.match.params.hash}/aceite`
    );
  }

  render() {
    const { prova } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#1A1E63",
          height: "100vh",
          paddingTop: "35px",
        }}
      >
        <Container fluid>
          <Row>
            <Col lg="3" md="3" />
            <Col lg="6" md="6">
              <div
                className={`card  ${styles.shadow}`}
                style={{ padding: "25px", marginBottom: "30px" }}
              >
                <div className="card-body">
                <div className="d-flex justify-content-end"><h6>{desfazHash(localStorage.getItem("user"))}</h6></div>
                  <h3
                    className="card-title"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {prova.titulo}
                  </h3>
                  <hr />
                  <p
                    dangerouslySetInnerHTML={{ __html: prova.texto }}
                    style={{ fontFamily: "Raleway", textAlign: "center" }}
                  ></p>
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => this.goToAceite()}
                    >
                      Estou pronto para começar a prova!
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="3" md="3" />
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Prova);
