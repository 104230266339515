import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import styles from "../styles.module.css";
import Header from "../../Header";

class ProvaRealizada extends React.Component {
  backHome() {
    this.props.history.push("/");
  }

  render() {
    return (
      <>
        <Header />
        <Container>
          <Row style={{ marginTop: "45px" }}>
            <Col lg="12" md="12">
              <div
                className={`card  ${styles.shadow}`}
                style={{ padding: "25px", marginBottom: "30px" }}
              >
                <div className="card-body">
                  <h3
                    className="card-title"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    Prova já realizada!
                  </h3>
                  <div className="d-flex justify-content-center" style={{marginTop: "15px"}}>
                    <button
                      style={{
                        backgroundColor: "#EFA884",
                        color: "#82604E",
                        border: "2px #E5A17E solid",
                        borderRadius: "50px",
                        fontFamily: "Raleway",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "10px 25px 10px 25px",
                      }}
                      type="button"
                      onClick={() => this.backHome()}
                    >
                      Voltar para home
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(ProvaRealizada);
