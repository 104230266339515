import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import MessageComponent from "./MessageComponent";
import TitleComponent from "./TitleComponent";
import ButtonComponent from "./ButtonComponent";
import ButtonDocComponent from "./ButtonDocComponent";
import ButtonDocSegundoEnvioComponent from "./ButtonDocSegundoEnvioComponent";
import styles from "./styles.module.css";
import { FiArrowRightCircle } from "react-icons/fi";
import api from "../../../services/api";
import { fazHash, desfazHash } from "../../../services/hash";
const dayjs = require("dayjs");

class ProvaDashboard extends React.Component {
  state = {
    now: "",
    dataFormatada: "",
    status: "",
    documento_id: "",
    resultado: "",
    prazoDoc: "",
    prazoDocFormatado: "",
    statuses: [],
    docRep: {},
    statusRep: [],
  };

  componentDidMount() {
    let now = dayjs();
    this.setState({
      now: now.format("YYYY-MM-DD"),
    });

    let d3 = dayjs(new Date(this.props.prazo));
    this.setState({
      dataFormatada: d3.format("DD/MM/YYYY"),
    });

    if (this.props.status === "1") {
      if (this.props.provareprovadoid) {
        const data = new FormData();
        data.append("user_id", desfazHash(localStorage.getItem("id")));
        data.append("prova_id", this.props.id);
        api
          .post("documentos/get-documentacao-by-user-rep", data)
          .then((response) => {
            let d3 = dayjs(new Date(response.data.prazo));
            this.setState({
              status: response.data.documentacao.status,
              documento_id: response.data.documentacao.id,
              resultado: response.data.documentacao.resultado,
              prazoDoc: response.data.prazo,
              prazoDocFormatado: d3.format("DD/MM/YYYY"),
            });

            if (response.data.documentacao.resultado === null || response.data.documentacao.resultado === "aprovado") {
              api
                .get(
                  `/status/${response.data.documentacao.id}/get-status-by-documentos-segundo-envio`
                )
                .then((res) => {
                  this.setState({
                    statuses: res.data,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (response.data.documentacao.resultado === "reprovado") {
              api
                .get(
                  `documentos/${response.data.documentacao.id}/verify-doc-rep`
                )
                .then((res) => {
                  this.setState({
                    docRep: res.data,
                  });

                  api
                    .get(
                      `status/${res.data.id}/get-status-by-documentos-segundo-envio`
                    )
                    .then((res) => {
                      this.setState({
                        statusRep: res.data,
                      });
                    })
                    .catch((error) => console.warn(error));
                })
                .catch((error) => console.warn(error));
            }
          })
          .catch((error) => console.warn(error));
      } else if (this.props.provaid) {
        const data = new FormData();
        data.append("user_id", desfazHash(localStorage.getItem("id")));
        data.append("prova_id", this.props.id);
        api
          .post("documentos/get-documentacao-by-user", data)
          .then((response) => {
            let d3 = dayjs(new Date(response.data.prazo));
            this.setState({
              status: response.data.documentacao.status,
              documento_id: response.data.documentacao.id,
              resultado: response.data.documentacao.resultado,
              prazoDocFormatado: d3.format("DD/MM/YYYY"),
              prazoDoc: response.data.prazo,
            });

            if (response.data.documentacao.resultado === null || response.data.documentacao.resultado === "aprovado") {
              api
                .get(
                  `/status/${response.data.documentacao.id}/get-status-by-documentos`
                )
                .then((res) => {
                  this.setState({
                    statuses: res.data,
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (response.data.documentacao.resultado === "reprovado") {
              api
                .get(
                  `documentos/${response.data.documentacao.id}/verify-doc-rep`
                )
                .then((res) => {
                  this.setState({
                    docRep: res.data,
                  });

                  api
                    .get(
                      `status/${res.data.id}/get-status-by-documentos-segundo-envio`
                    )
                    .then((res) => {
                      this.setState({
                        statusRep: res.data,
                      });
                    })
                    .catch((error) => console.warn(error));
                })
                .catch((error) => console.warn(error));
            }
          })
          .catch((error) => console.warn(error));
      }
    }
  }

  formatDate(date) {
    let d3 = dayjs(new Date(date));
    return d3.format("DD/MM/YYYY");
  }

  render() {
    const { statuses, statusRep } = this.state;
    return (
      <div
        className={`card  ${styles.shadow}`}
        style={{ padding: "25px", marginBottom: "30px" }}
      >
        <div className="card-body">
          <h5 className="card-title">
            <TitleComponent
              title={this.props.title}
              status={this.props.status}
            />
          </h5>

          {this.props.status != null && (
            <>
              <h6
                className="card-subtitle mb-2 text-muted"
                style={{
                  fontFamily: "Raleway",
                  fontSize: "15px",
                  marginTop: "10px",
                  fontWeight: "600",
                }}
              >
                Sua pontuação é <strong>{this.props.pontuacao}.00</strong>{" "}
                pontos
              </h6>
            </>
          )}
          {this.state.status === null && (
            <>
              {this.state.prazoDoc >= this.state.now && (
                <>
                  <hr style={{ marginTop: "20px" }} />
                  <h5 className="card-title" style={{ marginTop: "35px" }}>
                    <TitleComponent
                      title={`Documentação pendente`}
                      status={null}
                    />
                  </h5>
                  <MessageComponent
                    message={`Realize o envio da documentação requerida. O prazo máximo para
                realização é <mark style={{color: '#fff', backgroundColor: "#343a40"}}><strong>${this.state.dataFormatada}</strong></mark>`}
                    dataFormatada={this.state.prazoDocFormatado}
                  />
                  <br />
                  <br />
                  <ButtonDocComponent
                    id={this.props.id}
                    provaid={this.props.provaid}
                    provarep={this.props.provareprovadoid}
                  />
                </>
              )}
            </>
          )}

          {this.state.prazoDoc < this.state.now && (
            <>
              {this.state.status === null && (
                <>
                  <hr style={{ marginTop: "20px" }} />
                  <p className="card-text">
                    <MessageComponent
                      dataFormatada={this.state.dataFormatada}
                      message={`O prazo para envio da documentação foi ultrapassado. Entre em contato com a equipe <mark style={{color: '#60510F'}}><a href="mailto:educa@coachingintegrado.com.br"><strong>educa@coachingintegrado.com.br</strong></a></mark>`}
                    />
                  </p>
                </>
              )}
            </>
          )}

          {this.state.resultado === null && (
            <>
              {" "}
              <hr style={{ marginTop: "20px" }} />
              <h5 className="card-title" style={{ marginTop: "35px" }}>
                <TitleComponent
                  title={`Status: análise da documentação`}
                  status={null}
                />
              </h5>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Status atual</th>
                    <th scope="col">Data de atualização</th>
                  </tr>
                </thead>
                <tbody>
                  {statuses.map((status) => (
                    <>
                      <tr>
                        <td>
                          {status.descricao}{" "}
                          {status.prazoFinalAnalise != null && (
                            <>
                              <mark>
                                <span
                                  style={{
                                    fontWeight: "600",
                                    color: "#bd9e00",
                                  }}
                                >
                                  {this.formatDate(status.prazoFinalAnalise)}
                                </span>
                              </mark>
                            </>
                          )}
                        </td>
                        <td>{this.formatDate(status.dataAtualizacao)}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {this.state.resultado === "reprovado" && (
            <>
              {this.state.docRep.prazo >= this.state.now && (
                <>
                  {this.state.docRep.status === null && (
                    <>
                      <hr style={{ marginTop: "20px" }} />
                      <h5 className="card-title" style={{ marginTop: "35px" }}>
                        <TitleComponent
                          title={`Documentação pendente`}
                          status={null}
                        />
                      </h5>
                      <MessageComponent
                        message={`Realize o envio da documentação requerida. O prazo máximo para
                realização é <mark style={{color: '#fff', backgroundColor: "#343a40"}}><strong>${this.state.dataFormatada}</strong></mark>`}
                        dataFormatada={this.state.prazoDocFormatado}
                      />
                      <br />
                      <br />
                      <ButtonDocSegundoEnvioComponent
                        id={this.state.docRep.id}
                        provaid={this.props.provaid}
                        provarep={this.props.provareprovadoid}
                      />
                    </>
                  )}
                </>
              )}
              {this.state.docRep.prazo < this.state.now && (
                <>
                  {this.state.docRep.status === null && (
                    <>
                      <hr style={{ marginTop: "20px" }} />
                      <p className="card-text">
                        <MessageComponent
                          dataFormatada={this.state.dataFormatada}
                          message={`O prazo para envio da documentação foi ultrapassado. Entre em contato com a equipe <mark style={{color: '#60510F'}}><a href="mailto:educa@coachingintegrado.com.br"><strong>educa@coachingintegrado.com.br</strong></a></mark>`}
                        />
                      </p>
                    </>
                  )}
                </>
              )}
              {this.state.docRep.status === 1 && (
                <>
                  <>
                    {" "}
                    <hr style={{ marginTop: "20px" }} />
                    <h5 className="card-title" style={{ marginTop: "35px" }}>
                      <TitleComponent
                        title={`Status: análise da documentação`}
                        status={null}
                      />
                    </h5>
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Status atual</th>
                          <th scope="col">Data de atualização</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusRep.map((status) => (
                          <>
                            <tr>
                              <td>
                                {status.descricao}{" "}
                                {status.prazoFinalAnalise != null && (
                                  <>
                                    <mark>
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          color: "#bd9e00",
                                        }}
                                      >
                                        {this.formatDate(
                                          status.prazoFinalAnalise
                                        )}
                                      </span>
                                    </mark>
                                  </>
                                )}
                              </td>
                              <td>{this.formatDate(status.dataAtualizacao)}</td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </>
                </>
              )}
            </>
          )}

          {this.props.status === null && (
            <>
              {this.props.prazo >= this.state.now && (
                <>
                  <p className="card-text">
                    <MessageComponent
                      dataFormatada={this.state.dataFormatada}
                      message={`Você possui uma prova pendente de realização. O prazo máximo para realização é <mark style={{color: '#fff', backgroundColor: "#343a40"}}><strong>${this.state.dataFormatada}</strong></mark>`}
                    />
                  </p>
                </>
              )}
              {this.props.prazo <= this.state.now && (
                <p className="card-text">
                  <MessageComponent
                    dataFormatada={this.state.dataFormatada}
                    message={`O prazo para realização da prova foi ultrapassado. Entre em contato com a equipe <mark style={{color: '#60510F'}}><a href="mailto:educa@coachingintegrado.com.br"><strong>educa@coachingintegrado.com.br</strong></a></mark>`}
                  />
                </p>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ProvaDashboard;
