export const TOKEN_KEY = "@ici-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user")
    localStorage.removeItem("email")
    localStorage.removeItem("id")
    localStorage.removeItem("questao_id")
    localStorage.removeItem("pageMax")
    localStorage.removeItem("doc")
    localStorage.removeItem("doc_rep")
    localStorage.removeItem("count");
    localStorage.removeItem("type");
    localStorage.removeItem("questa_id_reprovado")
}