import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import api from "../../services/api";
import { login } from "../../services/auth";
import { fazHash, desfazHash } from "../../services/hash";
import { Link, useHistory, withRouter } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../styles.module.css";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`} 
  }
`;
//const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationContainer = styled.div`
${(props) => `background-image: url("https://i.imgur.com/h0Nu1bl.png");`}
${tw`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`}
`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-cover`}
`;

const SubmitButtonIcon = LoginIcon;
const illustrationImageSrc = illustration;

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: "",
    loading: false,
  };

  handleSignIn = async (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("login", { email, password }, {
          credentials: 'include'
        });
        login(response.data.access_token);
        localStorage.setItem("user", fazHash(response.data.name));
        localStorage.setItem("email", fazHash(response.data.email));
        localStorage.setItem("id", fazHash(response.data.id));
        localStorage.setItem("type", fazHash(response.data.type));
        localStorage.setItem("type_log", response.data.type);
        localStorage.setItem("count", 3600);
        this.setState({
          loading: false,
        });
        this.props.history.push("/");
      } catch (err) {
        this.setState({
          error: "Houve um problema com o login, verifique suas credenciais.",
          loading: false,
        });
      }
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <AnimationRevealPage>
        <div>
          <Container style={{ backgroundColor: "#1A1E63" }}>
            <Content>
              <MainContainer>
                <MainContent>
                  <Heading className="d-flex justify-content-center">
                    <img
                      width="49%"
                      src="https://cdn-cms.f-static.net/uploads/256012/400_5a980d28d02e1.jpg"
                    ></img>
                  </Heading>
                  <FormContainer>
                    <DividerTextContainer>
                      <DividerText>Realizar login</DividerText>
                    </DividerTextContainer>
                    <Form role="form" onSubmit={this.handleSignIn}>
                      <Input
                        type="email"
                        placeholder="Email"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                      <Input
                        type="password"
                        placeholder="Senha"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      <SubmitButton type="submit" disabled={loading === true}>
                        {loading === true && (
                          <>
                            <div className="row">
                              <div
                                className="col-auto"
                                style={{ paddingLeft: "8px" }}
                              >
                                <CircleSpinner
                                  size={20}
                                  color="#fff"
                                  loading={loading}
                                ></CircleSpinner>
                              </div>
                              <div className="col">
                                <span
                                  style={{
                                    paddingTop: "4px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  Realizando login...
                                </span>
                              </div>
                            </div>
                          </>
                        )}{" "}
                        {loading === false && (
                          <>
                            <SubmitButtonIcon className="icon" />
                            <span className="text">Login</span>
                          </>
                        )}
                      </SubmitButton>
                    </Form>
                  </FormContainer>
                </MainContent>
              </MainContainer>
              <IllustrationContainer>
                <IllustrationImage imageSrc={illustrationImageSrc} />
              </IllustrationContainer>
            </Content>
          </Container>
        </div>
      </AnimationRevealPage>
    );
  }
}

export default withRouter(Login);
