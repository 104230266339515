import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../services/api";
import { desfazHash, fazHash } from "../../services/hash";
import TranscricaoUpload from "./TranscricaoUpload";
import AutoAnaliseUpload from "./AutoAnaliseUpload";
import AudioUpload from "./AudioUpload";
import InfoUpload from "./InfoUpload";
import "./styles.css";
import styles from "./styles.module.css";
import { CircleSpinner } from "react-spinners-kit";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import Header from "views/Header";
import ComprovantePagamento from "./ComprovantePagamento";

class DocumentacaoRep extends React.Component {
  state = {
    loading: false,
    disabled: true,
    messageTranscricao: false,
    messageAudio: false,
    messageAutoAnalise: false,
  };

  realizarEnvioDocumentacao() {
    const data = new FormData();
    data.append("prova_id", this.props.match.params.id);
    data.append("user_id", desfazHash(localStorage.getItem("id")));
    data.append("value", this.props.match.params.value);
    api
      .post("documentos/status-doc-reprovado", data)
      .then((response) => {
        this.setState({
          loading: false,
        });
        this.props.history.push("/");
      })
      .catch((error) => this.setState({ loading: false }));
  }

  myCallback = (childData) => {
    this.setState({ messageTranscricao: childData });
  };

  myCallbackAutoAnalise = (childData) => {
    this.setState({ messageAutoAnalise: childData });
  };

  myCallbackAudio = (childData) => {
    console.log(childData);
    this.setState({ disabled: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <Header />
        <Container>
          <Row style={{ marginTop: "60px" }}>
            <Col lg="12" md="12">
              <div
                className={`card  ${styles.shadow}`}
                style={{ padding: "25px", marginBottom: "30px" }}
              >
                <div className="card-body">
                  <h3
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      marginBottom: "30px",
                    }}
                  >
                    Submeter a documentação
                  </h3>
                  <InfoUpload />
                  <TranscricaoUpload parentCallback={this.myCallback} />
                  <br />
                  <AutoAnaliseUpload
                    parentCallback={this.myCallbackAutoAnalise}
                  />
                  <br />
                  <AudioUpload parentCallback={this.myCallbackAudio} />

                  <ComprovantePagamento />
                  <br />
                  <br />
                  <div className="d-flex justify-content-center">
                    <button
                      className={
                        `${styles.buttonProva} ` +
                        (this.state.disabled === true
                          ? ` ${styles.disabled}`
                          : "")
                      }
                      onClick={() => this.realizarEnvioDocumentacao()}
                      disabled={this.state.disabled === true}
                    >
                      {loading === true && (
                        <>
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{ paddingLeft: "8px" }}
                            >
                              <CircleSpinner
                                size={20}
                                color="#fff"
                                loading={loading}
                              ></CircleSpinner>
                            </div>
                            <div className="col">
                              <span
                                style={{
                                  paddingTop: "4px",
                                  verticalAlign: "middle",
                                }}
                              >
                                Submetendo documentação...
                              </span>
                            </div>
                          </div>
                        </>
                      )}{" "}
                      {loading === false && <>Enviar documentação</>}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(DocumentacaoRep);
