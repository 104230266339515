import React from "react";

class MessageComponent extends React.Component {
  render() {
    return (
      <>
        <span
          dangerouslySetInnerHTML={{ __html: this.props.message }}
          style={{ fontFamily: "Raleway", fontWeight: "500", color: "#242432" }}
        ></span>
      </>
    );
  }
}

export default MessageComponent;
